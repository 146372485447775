import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DynamicDocForm = makeShortcode("DynamicDocForm");
const FormValues = makeShortcode("FormValues");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When public ACME Certificate Authorities (CA) like Let's Encrypt issue certificates to clients in the Web PKI, the CA depends heavily on DNS for client verification. To get a certificate, a client must prove to the CA that it either directly controls the public DNS records for a domain (for the DNS-01 challenge type)—or that it controls the IP address pointed to by public DNS records (for the HTTP-01 and TLS-ALPN-01 challenge types).`}</p>
    <p>{`When deploying ACME internally, the same constraints are true: A server that authorizes certificate requests must be able to query internal DNS servers or reach internal hosts. This is why we created a Registration Authority (RA) for Certificate Manager. An RA is a service you run on your own network that authenticates ACME challenges (or other certificate requests). It does not issue certificates or hold CA keys. Instead, it relays authenticated certificate requests to Certificate Manager, which then issues certificates.`}</p>
    <p>{`In this tutorial, you'll configure a Remote RA that you can run to authenticate requests for Certificate Manager certificates.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/certificate_manager_ra_mode.png",
        "alt": "Example PKI topology with Certificate Manager plus RA Mode"
      }}></img></p>
    <h2>{`Before you begin`}</h2>
    <p>{`You will need:`}</p>
    <ul>
      <li parentName="ul">{`An account on the Smallstep platform. Need one? `}<a parentName="li" {...{
          "href": "https://smallstep.com/signup?product=cm"
        }}>{`Register here`}</a></li>
      <li parentName="ul">{`An Authority in Certificate Manager that will act as your upstream CA`}</li>
      <li parentName="ul">{`A host or Kubernetes cluster where you can run the Registration Authority`}</li>
    </ul>
    <h3>{`Optional`}</h3>
    <p>{`Use this form to pre-fill some of the examples with values from your setup.`}</p>
    <DynamicDocForm formFields={[{
      label: "Certificate Manager Authority URL",
      name: "caUrl",
      placeholder: "https://example.mycompany.ca.smallstep.com"
    }, {
      label: "Certificate Manager Authority Fingerprint",
      name: "fingerprint",
      placeholder: "b4fc6b547ca4610b69cfcc53c6933e7a37170476dfe13EXAMPLE92c403f5"
    }, {
      label: "Certificate Manager JWK Provisioner Name",
      name: "provisioner",
      placeholder: "acme-ra-jwk"
    }, {
      label: "Registration Authority DNS Names",
      name: "dns",
      placeholder: "ra.example.com, ca.example.io"
    }, {
      label: "RA Bind Port or Address",
      name: "address",
      placeholder: ":443"
    }, {
      label: "Registration Authority URL",
      name: "raUrl",
      placeholder: "https://ra.example.com"
    }]} mdxType="DynamicDocForm" />
    <h2>{`Table of Contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#run-a-registration-authority-on-linux"
        }}>{`Run an RA on Linux`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#option-1-use-our-linux-ra-install-script"
            }}>{`Option 1: Use our Linux install script`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#option-2-manual-ra-setup"
            }}>{`Option 2: Manual RA Setup`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#run-a-registration-authority-on-kubernetes"
        }}>{`Run an RA on Kuberenets`}</a></li>
    </ul>
    <h2>{`Run a Registration Authority on Linux`}</h2>
    <h3>{`Option 1: Use our Linux RA install script`}</h3>
    <h4>{`0. Before you begin`}</h4>
    <p>{`Create a JWK provisioner in Certificate Manager.
Your RA will use this provisioner to get certificates.`}</p>
    <h4>{`1. Create your RA`}</h4>
    <p>{`Our `}<a parentName="p" {...{
        "href": "https://files.smallstep.com/install-step-ra.sh"
      }}>{`setup script`}</a>{` will install an RA on a Linux host (Debian-based distros).`}</p>
    <p>{`The script will:`}</p>
    <ul>
      <li parentName="ul">{`Install the `}<inlineCode parentName="li">{`step`}</inlineCode>{` and `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` binaries in `}<inlineCode parentName="li">{`/usr/bin`}</inlineCode></li>
      <li parentName="ul">{`Connect to your Certificate Manager CA`}</li>
      <li parentName="ul">{`Help you choose a JWK provisioner on your CA to link your RA to`}</li>
      <li parentName="ul">{`Create RA configuration files in `}<inlineCode parentName="li">{`/etc/step-ca`}</inlineCode></li>
      <li parentName="ul">{`Create a `}<inlineCode parentName="li">{`step`}</inlineCode>{` user and group`}</li>
      <li parentName="ul">{`Add a systemd service called `}<inlineCode parentName="li">{`step-ca.service`}</inlineCode></li>
      <li parentName="ul">{`Enable and start `}<inlineCode parentName="li">{`step-ca.service`}</inlineCode></li>
      <li parentName="ul">{`Export a `}<inlineCode parentName="li">{`STEPPATH`}</inlineCode>{` variable in `}<inlineCode parentName="li">{`/root/.bash_profile`}</inlineCode></li>
    </ul>
    <p>{`The script has the following requirements:`}</p>
    <ul>
      <li parentName="ul">{`It must be run on a machine that uses systemd`}</li>
      <li parentName="ul">{`It must be run as `}<inlineCode parentName="li">{`root`}</inlineCode></li>
      <li parentName="ul">{`It requires `}<inlineCode parentName="li">{`jq`}</inlineCode>{`, `}<inlineCode parentName="li">{`curl`}</inlineCode>{`, and `}<inlineCode parentName="li">{`tar`}</inlineCode>{` commands`}</li>
    </ul>
    <p>{`To run the script:`}</p>
    <FormValues mdxType="FormValues">
      {({
        caUrl = "https://example.mycompany.ca.smallstep.com",
        provisioner = "acme-ra-jwk",
        dns = "ra.example.com",
        address = ":443",
        fingerprint = "b4fc6b547ca4610b69cfcc53c6933e7a37170476dfe13EXAMPLE92c403f5"
      }) => <CodeBlock language="shell-session" copyText={`curl -sSLO https://files.smallstep.com/install-step-ra.sh && \\
bash install-step-ra.sh \\
    --ca-url ${caUrl} \\
    --fingerprint ${fingerprint} \\
    --provisioner-name ${provisioner} \\
    --dns-names ${dns} \\
    --listen-address ${address}`} mdxType="CodeBlock">
        {`# curl -sSLO https://files.smallstep.com/install-step-ra.sh
# bash install-step-ra.sh \\
    --ca-url ${caUrl} \\
    --fingerprint ${fingerprint} \\
    --provisioner-name "${provisioner}" \\
    --dns-names "${dns}" \\
    --listen-address ${address}`}
      </CodeBlock>}
    </FormValues>
    <p>{`Your RA service should now be running. It is configured with a single ACME provisioner, but you can add other provisioners as needed.`}</p>
    <p>{`Then follow the prompts to configure and start your RA.`}</p>
    <p>{`Check that your RA server is running properly:`}</p>
    <FormValues mdxType="FormValues">
      {({
        address = ":443",
        fingerprint = "b4fc6b547ca4610b69cfcc53c6933e7a37170476dfe13EXAMPLE92c403f5"
      }) => <CodeBlock language="shell-session" copyText={`journalctl -fu step-ca.service`} mdxType="CodeBlock">
        {`# journalctl -fu step-ca.service
-- Logs begin at Wed 2021-07-28 18:57:26 UTC. --
Jul 28 19:05:34 ip-172-31-28-100 systemd[1]: Started step-ca service.
Jul 28 19:05:35 ip-172-31-28-100 step-ca[2013]: badger 2021/07/28 19:05:35 INFO: All 0 tables opened in 0s
Jul 28 19:05:35 ip-172-31-28-100 step-ca[2013]: badger 2021/07/28 19:05:35 INFO: No head keys found
Jul 28 19:05:35 ip-172-31-28-100 step-ca[2013]: 2021/07/28 19:05:35 Using root fingerprint '${fingerprint}'
Jul 28 19:05:35 ip-172-31-28-100 step-ca[2013]: 2021/07/28 19:05:35 Serving HTTPS on ${address} ...`}
      </CodeBlock>}
    </FormValues>
    <p>{`You have completed the basic setup of your RA.`}</p>
    <h4>{`2. Distribute your root CA certificate and start issuing certs. 🥳`}</h4>
    <p>{`To download your root certificate from anywhere on your network, run the following, substituting your RA's URL and the root CA fingerprint:`}</p>
    <FormValues mdxType="FormValues">
      {({
        caUrl = "https://example.mycompany.ca.smallstep.com",
        fingerprint = "b4fc6b547ca4610b69cfcc53c6933e7a37170476dfe13EXAMPLE92c403f5"
      }) => <CodeBlock language="shell-session" copyText={`step ca root root_ca.crt --ca-url ${caUrl} --fingerprint ${fingerprint}`} mdxType="CodeBlock">
        {`$ step ca root root_ca.crt --ca-url ${caUrl} --fingerprint ${fingerprint}`}
      </CodeBlock>}
    </FormValues>
    <p>{`You can then get a certificate manually, using `}<inlineCode parentName="p">{`step`}</inlineCode>{` with the RA's ACME provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ step ca certificate my-hostname.example.com server.crt server.key
`}</code></pre>
    <h4>{`3. Configure your local ACME clients to use your RA (instead of Let's Encrypt)`}</h4>
    <p>{`We've written a `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/tutorials/acme-protocol-acme-clients"
      }}>{`tutorial for configuring popular ACME clients to use internal ACME`}</a>{`.`}</p>
    <h3>{`Option 2: Manual RA Setup`}</h3>
    <p>{`Setting up an RA manually involves the following steps:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create an Authority in Certificate Manager with a JWK provisioner`}</p>
        <p parentName="li">{`First, create a hosted Authority in the Certificate Manager dashboard if you haven't already, and configure your local `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI to access this Authority, using `}<inlineCode parentName="p">{`step ca bootstrap`}</inlineCode>{`.`}</p>
        <p parentName="li">{`Now, as a Super Administrator, add a JWK provisioner to the Authority, and give it a name (eg. `}<inlineCode parentName="p">{`acme-ra-jwk`}</inlineCode>{`):`}</p>
        <FormValues mdxType="FormValues">
          {({
            provisioner = "acme-ra-jwk"
          }) => <CodeBlock language="shell-session" copyText={`step ca provisioner add ${provisioner} --type JWK --create`} mdxType="CodeBlock">
  {`$ step ca provisioner add ${provisioner} --type JWK --create`}
          </CodeBlock>}
        </FormValues>
        <p parentName="li">{`You'll be asked for a password for the JWK encryption key. Provide a strong password and store it somewhere safe. You'll need it later in this setup process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Setup a local instance of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` that can act as a registration authority (RA).`}</p>
        <p parentName="li">{`You'll need to configure the RA to work with your hosted Authority in Certificate Manager, using the JWK provisioner you just created for the RA to Authority connection.`}</p>
        <p parentName="li">{`Create a `}<inlineCode parentName="p">{`/.step/config/ca.json`}</inlineCode>{` configuration file with the following contents:`}</p>
  <FormValues mdxType="FormValues">
   {({
            caUrl = "https://example.mycompany.ca.smallstep.com",
            provisioner = "acme-ra-jwk",
            dns = "ra.example.com",
            address = ":443",
            fingerprint = "b4fc6b547ca4610b69cfcc53c6933e7a37170476dfe13EXAMPLE92c403f5"
          }) => <CodeBlock autoCopy language="json" mdxType="CodeBlock">
    {`{
  "address": "${address}",
  "dnsNames": ["${dns.replace(',', '","')}"],
  "db": {
    "type": "badgerV2",
    "dataSource": "./.step/db"
  },
  "logger": {"format": "text"},
  "authority": {
    "type": "stepcas",
    "certificateAuthority": "${caUrl}",
    "certificateAuthorityFingerprint": "${fingerprint}",
    "certificateIssuer": {
      "type" : "jwk",
      "provisioner": "${provisioner}"
    },
    "provisioners": [{
      "type": "ACME",
      "name": "acme"
    }]
  },
  "tls": {
    "cipherSuites": [
      "TLS_ECDHE_ECDSA_WITH_CHACHA20_POLY1305",
      "TLS_ECDHE_ECDSA_WITH_AES_128_GCM_SHA256"
    ],
    "minVersion": 1.2,
    "maxVersion": 1.3,
    "renegotiation": false
  }
}`}
     </CodeBlock>}
  </FormValues>
        <p parentName="li">{`Substitute your own values for the following:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`address`}</inlineCode>{`: The address and port you would like your RA to listen on (eg. `}<inlineCode parentName="li">{`10.20.30.104:9000`}</inlineCode>{` or just `}<inlineCode parentName="li">{`:443`}</inlineCode>{` to bind to `}<inlineCode parentName="li">{`0.0.0.0:443`}</inlineCode>{`.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`certificateAuthority`}</inlineCode>{`: the URL of your upstream CA`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`certificateAuthorityFingerprint`}</inlineCode>{`: the CA fingerprint of your upstream CA`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`certificateIssuer.provisioner`}</inlineCode>{`: Your JWK provisioner name`}</li>
        </ul>
      </li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Next, put the issuing JWK provisioner password into a file (eg. `}<inlineCode parentName="p">{`$(step path)/password.txt`}</inlineCode>{`). When starting up `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` in RA mode, you must pass the issuing JWK provisioner password filename:`}</p>
        <CodeBlock language="shell-session" copyText="step-ca /.step/config/ca.json --issuer-password-file password.txt" mdxType="CodeBlock">
  {`$ step-ca /.step/config/ca.json --issuer-password-file password.txt`}
        </CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Distribute your root CA certificate and start issuing certs. 🥳`}</p>
        <p parentName="li">{`In another terminal run the following to download your root certificate from anywhere on your network, substituting your RA's URL and the root CA fingerprint:`}</p>
        <FormValues mdxType="FormValues">
          {({
            raUrl = "https://ra.example.com",
            fingerprint = "b4fc6b547ca4610b69cfcc53c6933e7a37170476dfe13EXAMPLE92c403f"
          }) => <CodeBlock language="shell-session" copyText={`step ca root root_ca.crt --ca-url ${raUrl} --fingerprint ${fingerprint}`} mdxType="CodeBlock">
  {`$ step ca root root_ca.crt --ca-url ${raUrl} --fingerprint ${fingerprint}`}
          </CodeBlock>}
        </FormValues>
        <p parentName="li">{`You can then get a certificate manually, using `}<inlineCode parentName="p">{`step`}</inlineCode>{` with the RA's ACME provisioner:`}</p>
        <CodeBlock language="shell-session" copyText="step ca certificate my-hostname.example.com server.crt server.key" mdxType="CodeBlock">
  {`$ step ca certificate my-hostname.example.com server.crt server.key`}
        </CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Configure your local ACME clients to use your RA (instead of Let's Encrypt)`}</p>
        <p parentName="li">{`We've written a `}<a parentName="p" {...{
            "href": "https://smallstep.com/docs/tutorials/acme-protocol-acme-clients"
          }}>{`tutorial for configuring popular ACME clients to use internal ACME`}</a>{`.`}</p>
      </li>
    </ol>
    <h2>{`Run a Registration Authority on Kubernetes`}</h2>
    <p>{`If you're running Kubernetes, you can run an ACME Registration Authority in your cluster. We have a Helm Chart for deploying an RA to Kubernetes. `}</p>
    <p><strong parentName="p">{`TL;DR:`}</strong>{` There's a setup script showing all of the steps together `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/helm-charts/tree/master/step-certificates/examples"
      }}>{`in this README`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create an Authority in Certificate Manager with a JWK provisioner`}</p>
        <p parentName="li">{`First, create a hosted Authority in the Certificate Manager dashboard if you haven't already, install the `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI locally (`}<inlineCode parentName="p">{`brew install step`}</inlineCode>{`), and configure `}<inlineCode parentName="p">{`step`}</inlineCode>{` to access your Authority, using `}<inlineCode parentName="p">{`step ca bootstrap`}</inlineCode>{`.`}</p>
        <p parentName="li">{`Now, as a Super Administrator, add a JWK provisioner to the Authority, and give it a name (eg. `}<inlineCode parentName="p">{`registration-authority`}</inlineCode>{`):`}</p>
        <CodeBlock language="shell-session" copyText="step ca provisioner add registration-authority --type JWK --create" mdxType="CodeBlock">
  {`$ step ca provisioner add registration-authority --type JWK --create`}
        </CodeBlock>
        <p parentName="li">{`You'll be asked for a password for the JWK encryption key. Provide a strong password and store it somewhere safe. You'll need it later in this setup process.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Configure the Helm Chart`}</p>
        <p parentName="li">{`Download the `}<inlineCode parentName="p">{`step_values.yml`}</inlineCode></p>
        <CodeBlock language="shell-session" copyText="curl -o step_values.yml -sSL https://raw.githubusercontent.com/smallstep/helm-charts/master/step-certificates/examples/registration_authority/values.yml" mdxType="CodeBlock">
  {`$ curl -o step_values.yml -sSL https://raw.githubusercontent.com/smallstep/helm-charts/master/step-certificates/examples/registration_authority/values.yml`}
        </CodeBlock>
        <p parentName="li">{`You will need to change the following fields in this YAML file to match your configuration:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`certificateAuthority`}</inlineCode>{` — your Certificate Manager Authority URL`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`certificateAuthorityFingerprint`}</inlineCode>{` — your Certificate Manager Authority fingerprint`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`certificateIssuer.provisioner`}</inlineCode>{` — the name of your JWK provisioner`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`dnsNames`}</inlineCode>{` — the DNS names you'd like your RA to be available on (these values will be added to the RA's TLS certificate subject)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`ca-url`}</inlineCode>{` — your Certificate Manager Authority URL`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`fingerprint`}</inlineCode>{` — your Certificate Manager Authority fingerprint`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`secrets.certificate_issuer.password`}</inlineCode>{` — the base64 encoded JWK encryption key password.  (`}<inlineCode parentName="li">{`echo 'your-jwk-password-here' | base64`}</inlineCode>{`)`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In Helm, add the Smallstep Helm charts repository`}</p>
        <CodeBlock language="shell-session" copyText="helm repo add smallstep https://smallstep.github.io/helm-charts/" mdxType="CodeBlock">
  {`$ helm repo add smallstep https://smallstep.github.io/helm-charts/`}
        </CodeBlock>
        <CodeBlock language="shell-session" copyText="helm repo update smallstep" mdxType="CodeBlock">
  {`$ helm repo update smallstep`}
        </CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Install the Helm chart`}</p>
        <CodeBlock language="shell-session" copyText="helm install -f step_values.yml smallstep/step-certificates" mdxType="CodeBlock">
  {`$ helm install -f step_values.yml smallstep/step-certificates`}
        </CodeBlock>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Configure your local ACME clients to use your RA (instead of Let's Encrypt)`}</p>
        <p parentName="li">{`We've written a `}<a parentName="p" {...{
            "href": "https://smallstep.com/docs/tutorials/acme-protocol-acme-clients"
          }}>{`tutorial for configuring popular ACME clients to use internal ACME`}</a>{`.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      